import { ReactComponent as VisibilityOn } from 'img/visibility_on.svg';
import { ReactComponent as VisibilityOff } from 'img/visibility_off.svg';
import styles from './Highlights.module.scss';
import Button from 'components/Button/Button';

type HighlightsBoxVisibleButtonProps = {
  onClick: () => void;
  isHighlightsBoxVisible?: boolean;
};

export default function HighlightsBoxVisibleButton({
  onClick,
  isHighlightsBoxVisible,
}: HighlightsBoxVisibleButtonProps) {
  return (
    <div className={styles.buttonWrapper}>
      <Button
        variant={isHighlightsBoxVisible ? 'light' : 'primary'}
        onClick={onClick}
      >
        {isHighlightsBoxVisible ? (
          <VisibilityOff className={styles.visibility} />
        ) : (
          <VisibilityOn className={styles.visibility} />
        )}
        <span className={styles.buttonText}>
          {isHighlightsBoxVisible
            ? 'Dölj Överblick för besökare'
            : 'Visa Överblick för besökare'}
        </span>
      </Button>
    </div>
  );
}
