import styles from './HighlightsHeader.module.scss';
import cn from 'classnames';
import { ReactComponent as WarningIcon } from 'img/direktcenter-info-icon.svg';
import { ReactComponent as DownArrowIcon } from 'img/arrow-down.svg';
import { trackEvent } from 'utils/statistics';

type HighlightsHeaderProps = {
  isExpanded: boolean;
  setIsExpanded: (isExpanded: boolean) => void;
  isHighlightsBoxVisible?: boolean;
};

export default function HighlightsHeader({
  isExpanded,
  setIsExpanded,
  isHighlightsBoxVisible,
}: HighlightsHeaderProps) {
  const trackUserInteraction = (eventName: string) => {
    trackEvent({
      name: eventName,
    });
  };

  return (
    <div
      className={cn({
        [styles.headingWrapper]: true,
        [styles.isNotHighlightsBoxVisible]: !isHighlightsBoxVisible,
      })}
    >
      <div className={styles.headingLeft}>
        {!isHighlightsBoxVisible && <WarningIcon />}
        <h3 className={styles.heading}>
          <b>ÖVERBLICK</b>
          {!isHighlightsBoxVisible && <>{` är dold för besökare`}</>}
        </h3>
      </div>
      <button
        className={styles.button}
        onClick={() => {
          setIsExpanded(!isExpanded);
          trackUserInteraction(
            isExpanded ? 'highlights-box-collapse' : 'highlights-box-expand'
          );
        }}
        type="button"
      >
        {isExpanded ? (
          <>
            Stäng <DownArrowIcon className={styles.buttonIconUp} />
          </>
        ) : (
          <>
            Öppna <DownArrowIcon className={styles.buttonIconDown} />
          </>
        )}
      </button>
    </div>
  );
}
