import ApiError from 'errors/ApiError';
import BaseError from 'errors/BaseError';
import NetworkError from 'errors/NetworkError';
import UnauthorizedError from 'errors/UnauthorizedError';
import _ from 'lodash';
import axios, { Method } from 'axios';
import { jwtDecode } from 'jwt-decode';
import queryString from 'query-string';
import {
  MISSING_AD_GROUP_ERROR_MESSAGE,
  NETWORK_ERROR_MESSAGE,
} from 'utils/apiErrorMessages';
import {
  AspectRatio,
  AstridImage,
  DirektcenterAttachment,
  DirektcenterAuthor,
  DirektcenterRoleType,
  FirebaseCredentials,
  Guest,
  PostId,
  StatisticsAttribute,
  Stream,
  StreamId,
} from 'Types';

const ERROR = 'Error';
const SUCCESS = 'Success';
const USER_DATA = 'UserData';

type HandlerCallback = (args: any) => void;

type Handler = {
  event: string;
  handler: HandlerCallback;
};

let accessToken: string | null | undefined = undefined;

axios.interceptors.request.use(
  (config) => {
    if (!(config.url ?? '').includes(process.env.REACT_APP_API_URL ?? '')) {
      return config;
    }

    if (accessToken) {
      config.headers = config.headers ?? {};
      config.headers['Authorization'] = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

function publish(handlers: Handler[], event: string, args: any) {
  handlers.forEach((topic) => {
    if (topic.event === event) {
      topic.handler(args);
    }
  });
}

// These types is probably not very accurate, but it's a start
const shouldRetry = (
  error: { response: { status: number; data: { data: string[] } } },
  originalRequest: { _retry: boolean }
) => {
  const isADGroupError =
    error?.response?.data?.data[0] === MISSING_AD_GROUP_ERROR_MESSAGE;

  const isUnauthorizedOrForbiddenError =
    error?.response?.status === 401 || error?.response?.status === 403;

  return (
    !isADGroupError && isUnauthorizedOrForbiddenError && !originalRequest._retry
  );
};

interface ReqParams<RequestData> {
  url: string;
  method: Method;
  data?: RequestData;
}

// Rejected due to server error. Always sent together with 5xx status code.
interface ErrorResponse {
  status: 'error';
  message: string;
  error?: Error;
}

// Rejected due to invalid data or call condition. Always sent together with 4xx status code.
interface FailResponse {
  status: 'fail';
  data: any[];
}

// Successful response. Always sent together with 2xx status code.
type SuccessResponse<ResponseData> = {
  status: 'success';
  data: ResponseData;
};

class Api {
  userData: UserData | null;

  baseUrl?: string;

  handlers: Handler[];

  constructor() {
    this.userData = null;
    this.baseUrl = process.env.REACT_APP_API_URL;
    this.handlers = [];
    this._updateAccessToken = this._updateAccessToken.bind(this);

    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {
        const originalRequest = error.config;

        if (shouldRetry(error, originalRequest)) {
          originalRequest._retry = true;
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/refresh-token`,
            {
              method: 'POST',
              credentials: 'include',
              headers: {
                'Content-Type': 'application/json',
              },
            }
          );

          if (response?.status === 200) {
            const responseData = await response.json();
            this._updateAccessToken(responseData);
            return axios({
              ...originalRequest,
              headers: {
                ...originalRequest.headers,
                Authorization: `Bearer ${accessToken}`,
              },
            });
          } else {
            this._updateAccessToken({
              status: 'success',
              data: { token: null },
            });
            // throw new UnauthorizedError();
          }
        }

        if (error.message === NETWORK_ERROR_MESSAGE) {
          return Promise.reject(error);
        }

        return Promise.resolve({
          data: {
            status: 'error',
            message: error.message,
            error,
          },
        });
      }
    );
  }

  off() {
    accessToken = undefined;
    this.userData = null;
    this.handlers.splice(0, this.handlers.length);
  }

  on(event: string, handler: HandlerCallback, context?: HandlerCallback) {
    if (typeof context === 'undefined') {
      context = handler;
    }

    const eventHandler = { event, handler: handler.bind(context) };

    this.handlers.push(eventHandler);

    const removeEventHandler = () => {
      const index = this.handlers.findIndex(
        (handler) => handler === eventHandler
      );
      if (index > -1) {
        this.handlers.splice(index, 1);
      }
    };

    return removeEventHandler;
  }

  _updateAccessToken(response: UpdateAccessTokenParam) {
    if (response && response.status !== 'success') {
      return;
    }
    const newAccessToken = response?.data?.token;
    if (accessToken !== newAccessToken) {
      if (response === undefined) {
        accessToken = null;
        this.userData = null;
        return publish(this.handlers, USER_DATA, null);
      }

      if (accessToken === undefined && newAccessToken === null) {
        accessToken = null;
        this.userData = null;
        return publish(this.handlers, USER_DATA, null);
      }

      if (newAccessToken === accessToken) {
        return;
      }
      accessToken = newAccessToken;

      const userData = accessToken ? jwtDecode<UserData>(accessToken) : null;

      if (userData === null) {
        accessToken = null;
        this.userData = null;
        return publish(this.handlers, USER_DATA, null);
      }

      if (response.data?.firebaseCredentials) {
        userData.firebaseCredentials = response.data?.firebaseCredentials;
      } else if (userData) {
        userData.firebaseCredentials = this?.userData?.firebaseCredentials;
      }

      if (
        accessToken === undefined ||
        _.isEqual(this.userData, userData) === false
      ) {
        this.userData = userData;
        // TODO: rename event handler name from auth to userData and move to constant.
        publish(this.handlers, USER_DATA, this.userData);
      }
    }
  }

  async _req<RequestData, ResponseData>({
    url,
    method,
    data,
  }: ReqParams<RequestData>): Promise<
    SuccessResponse<ResponseData> | ErrorResponse | FailResponse
  > {
    try {
      const response = await axios({
        url: this.baseUrl + url,
        method: method,
        timeout: 1000 * 10, // 10 sec timeout for all API requests
        withCredentials: true,
        data,
        headers: !accessToken
          ? {}
          : {
              Authorization: `Bearer ${accessToken}`,
            },
      });

      if (response.status === 401 || response.status === 403) {
        throw new UnauthorizedError();
      }

      publish(this.handlers, SUCCESS, response.data);

      return response.data;
    } catch (err) {
      const { message } = err as { message: string };
      let error: Error;
      if (message === NETWORK_ERROR_MESSAGE) {
        error = new NetworkError();
      } else if (err instanceof BaseError === false) {
        error = new ApiError(message);
      } else {
        error = err as Error;
      }
      publish(this.handlers, ERROR, error);
      //TODO: Change return value from object to instance of an error only
      return {
        status: 'error',
        message,
        error,
      };
    }
  }

  _post<RequestData, ResponseData>(url: string, data: RequestData) {
    return this._req<RequestData, ResponseData>({ method: 'post', url, data });
  }

  _put<RequestData, ResponseData>(url: string, data: RequestData) {
    return this._req<RequestData, ResponseData>({ method: 'put', url, data });
  }

  _patch<RequestData, ResponseData>(url: string, data: RequestData) {
    return this._req<RequestData, ResponseData>({ method: 'patch', url, data });
  }

  _delete<ResponseData>(url: string) {
    return this._req<undefined, ResponseData>({ method: 'delete', url });
  }

  _get<ResponseData>(url: string) {
    return this._req<undefined, ResponseData>({ method: 'get', url });
  }

  async usersList() {
    return await this._get<UsersListResponse>(`/users`);
  }

  // TODO: change to /guests instead?
  async guestsList() {
    return await this._get<GuestsListResponse>(`/guest/list`);
  }

  async loginWithGuestToken(token: string) {
    const response = await this._post<
      LoginWithGuestTokenRequest,
      LoginWithGuestTokenResponse
    >('/guest/auth', {
      token,
    });
    this._updateAccessToken(response);
    return response;
  }

  async login({ email, password }: LoginRequest) {
    const response = await this._post<LoginRequest, LoginResponse>(
      '/users/auth',
      {
        email,
        password,
      }
    );
    this._updateAccessToken(response);
    return response;
  }

  async loginWithToken({ token }: LoginWithTokenRequest) {
    const response = await this._post<
      LoginWithTokenRequest,
      LoginWithTokenResponse
    >('/users/auth-token', {
      token,
    });
    this._updateAccessToken(response);
    return response;
  }

  async loginWithCurrentSession() {
    const response = await this._post<null, LoginWithCurrentSessionResponse>(
      `/refresh-token`,
      null
    );

    this._updateAccessToken(response);

    if (response.status === 'success' && accessToken) {
      try {
        const user = jwtDecode(accessToken);
        const firebaseCredentials = response.data?.firebaseCredentials;
        return { user, firebaseCredentials };
      } catch {
        return;
      }
    }
  }

  async updateMyUserDetails(userData: UpdateMyUserDetailsRequest) {
    const response = await this._patch<
      UpdateMyUserDetailsRequest,
      UpdateMyUserDetailsResponse
    >('/me', userData);
    this._updateAccessToken(response);
    return response;
  }

  async logout() {
    try {
      await this._post<null, null>('/refresh-token/revoke', null).then(
        this._updateAccessToken
      );
    } catch (err) {
      throw err;
    } finally {
      this._updateAccessToken({
        status: 'success',
        data: { token: accessToken || null },
      });
    }
  }

  async postDelete(streamId: StreamId, postId: PostId) {
    return await this._delete<PostDeleteResponse>(
      `/streams/${streamId}/posts/${postId}`
    );
  }

  async postUpdate(
    streamId: StreamId,
    postId: PostId,
    {
      body,
      attachment,
      pinned,
      highlighted,
      createdAt,
      author,
      signature,
    }: PostUpdateRequest
  ) {
    return await this._put<PostUpdateRequest, PostUpdateResponse>(
      `/streams/${streamId}/posts/${postId}`,
      {
        body,
        attachment,
        pinned,
        highlighted,
        createdAt,
        author,
        signature,
      }
    );
  }

  async postRenew(
    streamId: StreamId,
    postId: PostId,
    {
      body,
      attachment,
      pinned,
      highlighted,
      createdAt,
      author,
      signature,
    }: PostRenewRequest
  ) {
    return await this._put<PostRenewRequest, PostRenewResponse>(
      `/streams/${streamId}/posts/${postId}/renew`,
      {
        body,
        attachment,
        pinned,
        highlighted,
        createdAt,
        author,
        signature,
      }
    );
  }

  async postCreate(
    streamId: StreamId,
    { body, attachment, pinned, highlighted, author }: PostCreateRequest
  ) {
    return await this._post<PostCreateRequest, PostCreateResponse>(
      `/streams/${streamId}/posts`,
      {
        body,
        attachment,
        pinned,
        highlighted,
        author,
      }
    );
  }

  async pendingPostCount({
    streamId,
    createdAfter,
  }: {
    streamId: StreamId;
    createdAfter?: string;
  }) {
    const queryObject = _.omitBy({ createdAfter }, _.isUndefined);
    const query = queryString.stringify(queryObject);
    const potentialQuestionMark = query.length ? '?' : '';
    return await this._get<PendingPostCountResponse>(
      `/streams/${streamId}/pending-posts/count${potentialQuestionMark}${query}`
    );
  }

  async pendingPostList({
    streamId,
    limit,
    createdAtOrBefore,
  }: PendingPostListType) {
    const queryObject = _.omitBy({ limit, createdAtOrBefore }, _.isUndefined);
    const query = queryString.stringify(queryObject);
    const potentialQuestionMark = query.length ? '?' : '';
    const res = await this._get<PendingPostListResponse>(
      `/streams/${streamId}/pending-posts/${potentialQuestionMark}${query}`
    );

    return res;
  }

  async pendingPostPublish(
    streamId: StreamId,
    postId: PostId,
    reply: { author: DirektcenterAuthor; body: string }
  ) {
    return await this._post<
      PendingPostPublishRequest,
      PendingPostPublishResponse
    >(`/streams/${streamId}/pending-posts/${postId}/publish`, {
      reply,
    });
  }

  async pendingPostDecline(streamId: StreamId, postId: PostId) {
    return await this._delete<PendingPostDeclineResponse>(
      `/streams/${streamId}/pending-posts/${postId}`
    );
  }

  async pendingPostSetIsApprovedForGuest(
    streamId: StreamId,
    postId: PostId,
    isApprovedForGuest: boolean
  ) {
    return await this._put<
      PendingPostSetIsApprovedForGuestRequest,
      PendingPostSetIsApprovedForGuestResponse
    >(`/streams/${streamId}/pending-posts/${postId}`, {
      isApprovedForGuest,
    });
  }

  async pendingPostReportAnnoying(streamId: StreamId, postId: PostId) {
    return await this._put<PendingPostReportRequest, PendingPostReportResponse>(
      `/streams/${streamId}/pending-posts/${postId}/report`,
      {
        reason: 'annoying',
      }
    );
  }

  async pendingPostReportThreat(streamId: StreamId, postId: PostId) {
    return await this._put<PendingPostReportRequest, PendingPostReportResponse>(
      `/streams/${streamId}/pending-posts/${postId}/report`,
      {
        reason: 'threat',
      }
    );
  }

  async pendingPostUpdate(streamId: StreamId, postId: PostId, text: string) {
    return await this._put<PendingPostUpdateRequest, PendingPostUpdateResponse>(
      `/streams/${streamId}/pending-posts/${postId}/update`,
      text
    );
  }

  async pendingPostListVisitorPosts(
    streamId: StreamId,
    limit: number,
    offset: number
  ) {
    const queryObject = _.omitBy({ limit, offset }, _.isUndefined);
    const query = queryString.stringify(queryObject);
    const potentialQuestionMark = query.length ? '?' : '';

    return await this._get<PendingPostListVisitorPostsResponse>(
      `/streams/${streamId}/pending-posts/visitor-posts${potentialQuestionMark}${query}`
    );
  }

  async pendingPostListReported(streamId: StreamId, limit: number) {
    const queryObject = _.omitBy({ limit }, _.isUndefined);
    const query = queryString.stringify(queryObject);
    const potentialQuestionMark = query.length ? '?' : '';

    return await this._get<PendingPostListReportedResponse>(
      `/streams/${streamId}/pending-posts/reported${potentialQuestionMark}${query}`
    );
  }

  async pendingPostListReportedAll(
    limit: number,
    hideTestStream: boolean,
    excludeStatuses: string[] = []
  ) {
    const queryObject = _.omitBy(
      {
        limit,
        hideTestStream,
        excludeStatuses: excludeStatuses.join(','),
      },
      _.isUndefined
    );
    const query = queryString.stringify(queryObject);
    const potentialQuestionMark = query.length ? '?' : '';

    return await this._get<PendingPostListReportedAllResponse>(
      `/streams/anything/pending-posts/reportedAll${potentialQuestionMark}${query}`
    );
  }

  async pendingPostStatistics(streamId: StreamId) {
    return this._get<PendingPostStatisticsResponse>(
      `/streams/${streamId}/statistics`
    );
  }

  async streamDelete(id: StreamId) {
    return await this._delete<StreamDeleteResponse>(`/streams/${id}`);
  }

  async streamUpdate(
    id: StreamId,
    {
      title,
      isActive,
      isVisitorPostingEnabled,
      isHighlightsBoxVisible,
      createdAt,
      parentSection,
      section,
    }: StreamUpdateRequest
  ) {
    return await this._put<StreamUpdateRequest, StreamUpdateResponse>(
      `/streams/${id}`,
      {
        title,
        isActive,
        isVisitorPostingEnabled,
        isHighlightsBoxVisible,
        createdAt,
        parentSection,
        section,
      }
    );
  }

  async streamCreate({
    title,
    isActive,
    isVisitorPostingEnabled,
    isHighlightsBoxVisible,
    parentSection,
    section,
  }: StreamCreateRequest) {
    return await this._post<StreamCreateRequest, StreamCreateResponse>(
      '/streams',
      {
        title,
        isActive,
        isVisitorPostingEnabled,
        isHighlightsBoxVisible,
        parentSection,
        section,
      }
    );
  }

  async streamSearch(query: {
    limit: number;
    query: string;
    section?: string;
    parentSection?: string;
  }) {
    const params = new URLSearchParams({
      ...query,
      limit: query.limit.toString(),
    }).toString();

    return await this._get<StreamSearchResponse>(
      `/streams/search-internal/?${params}`
    );
  }

  async replyCreate(
    streamId: StreamId,
    postId: PostId,
    { body, author }: ReplyCreateRequest
  ) {
    return await this._post<ReplyCreateRequest, ReplyCreateResponse>(
      `/streams/${streamId}/posts/${postId}/replies`,
      {
        body,
        author,
      }
    );
  }

  async replyRemove(streamId: StreamId, postId: PostId, replyId: string) {
    return await this._delete<ReplyRemoveResponse>(
      `/streams/${streamId}/posts/${postId}/replies/${replyId}`
    );
  }

  async replyUpdate(
    streamId: StreamId,
    postId: PostId,
    replyId: string,
    { body, createdAt, author }: ReplyUpdateRequest
  ) {
    return await this._put<ReplyUpdateRequest, ReplyUpdateResponse>(
      `/streams/${streamId}/posts/${postId}/replies/${replyId}`,
      {
        body,
        createdAt,
        author,
      }
    );
  }

  async fetchOgData(url: string) {
    return await this._post<FetchOgDataRequest, FetchOgDataResponse>(
      '/open-graph',
      {
        url,
      }
    );
  }

  async fetchVideoData(videoId: string) {
    return await this._post<FetchVideoDataRequest, FetchVideoDataResponse>(
      '/video-data',
      {
        id: videoId,
      }
    );
  }

  async getFileUploadUrl() {
    return await this._get<GetFileUploadUrl>('/file-upload-url');
  }

  async getAvatarUploadUrl() {
    return await this._get<GetAvatarUploadUrlResponse>('/avatar-upload-url');
  }

  async guestDetails(token?: string) {
    return await this._post<GuestDetailsRequest, GuestDetailsResponse>(
      '/guest/details',
      {
        token,
      }
    );
  }

  async guestCreate(
    streamId: StreamId,
    { displayName, title, avatarPath, isModerationRequired }: GuestCreateRequest
  ) {
    return await this._post<GuestCreateRequest, GuestCreateResponse>(
      `/streams/${streamId}/guests`,
      {
        displayName,
        title,
        avatarPath,
        isModerationRequired,
      }
    );
  }

  async guestList(streamId: StreamId) {
    return await this._get<GuestListResponse>(`/streams/${streamId}/guests`);
  }

  async guestDelete(guestId: string) {
    await this._delete<GuestDeleteResponse>(`/guest/${guestId}`);
  }

  // TOOD: change to more appropriate name
  async me() {
    await this._updateAccessToken({
      status: 'success',
      data: { token: accessToken || null },
    });
    return this.userData;
  }
}

type PendingPostListReportedAllResponse = {
  hasMoreItems: boolean;
  pendingPosts: PendingPost[];
};

type PendingPostListVisitorPostsResponse = {
  hasMoreItems: boolean;
  pendingPosts: PendingPost[];
};

type PendingPostListReportedResponse = {
  hasMoreItems: boolean;
  pendingPosts: PendingPost[];
};

type StreamSearchResponse = {
  searchResults: Stream[];
  hasMoreItems: boolean;
};

type UsersListResponse = {
  users: UserData[];
};

type GuestListResponse = {
  displayName: string;
  id: number;
  initiator: string;
  isModerationRequired: boolean;
  role: 'guest';
  streamId: StreamId;
  title: string;
  token: string;
};

type GuestDeleteResponse = {
  id: string;
};

type ReplyRemoveResponse = {
  id: string;
};

type StreamDeleteResponse = {
  id: StreamId;
};

type FetchVideoDataRequest = {
  id: string;
};

type FetchVideoDataResponse = {
  svtId: string;
  legacyId: string;
  aspectRatio: AspectRatio;
  posterImageId: string;
  duration: number;
  astridImage: AstridImage;
};

type FetchOgDataRequest = {
  url: string;
};

type FetchOgDataResponse = {
  ogTitle: string;
  ogUrl: string;
  ogDescription: string;
  ogImage?: { url: string };
  escenicImageId: string;
  escenicImageVersion: string;
  astridImage?: AstridImage;
};

type PendingPostUpdateRequest = string;

type PendingPostUpdateResponse = {
  pendingPostId: string;
};

type PendingPostReportRequest = {
  reason: 'threat' | 'annoying';
};

type PendingPostReportResponse = {
  updatedId: string;
};

type PendingPostSetIsApprovedForGuestRequest = {
  isApprovedForGuest: boolean;
};

type PendingPostSetIsApprovedForGuestResponse = {
  updatedId: string;
};

type GuestCreateRequest = {
  displayName: string;
  title: string;
  avatarPath: string;
  isModerationRequired: boolean;
};

type GuestCreateResponse = {
  guestId: string;
};

type ReplyUpdateRequest = {
  author: DirektcenterAuthor;
  body: string;
  createdAt: string;
};

type ReplyUpdateResponse = {
  id: string;
};

type ReplyCreateRequest = {
  author: DirektcenterAuthor;
  body: string;
};

type ReplyCreateResponse = {
  id: string;
};

type StreamCreateRequest = {
  title: string;
  isActive: boolean;
  isVisitorPostingEnabled: boolean;
  isHighlightsBoxVisible: boolean;
  parentSection: string;
  section: string;
};

type StreamCreateResponse = {
  id: StreamId;
};

type StreamUpdateRequest = {
  title: string;
  isActive: boolean;
  isVisitorPostingEnabled: boolean;
  isHighlightsBoxVisible: boolean;
  createdAt: string;
  parentSection: string;
  section: string;
};

type StreamUpdateResponse = {
  id: StreamId;
};

type PendingPostDeclineResponse = {
  deletedId: PostId;
};

type PendingPostPublishResponse = {
  postId: PostId;
};

type PendingPostPublishRequest = {
  reply: { author: DirektcenterAuthor; body: string };
};

type PendingPostCountResponse = {
  pendingPostsCount: number;
  hasGuests: boolean;
};

type PostCreateResponse = {
  id: PostId;
};

type PostCreateRequest = {
  author: DirektcenterAuthor;
  body: string;
  attachment?: DirektcenterAttachment | null;
  pinned: boolean;
  highlighted: boolean;
};

type PostRenewResponse = {
  id: PostId;
};

type PostRenewRequest = {
  author: DirektcenterAuthor;
  body: string;
  attachment: DirektcenterAttachment | null;
  pinned: boolean;
  highlighted: boolean;
  createdAt: string;
  signature?: string;
};

type PostUpdateResponse = {
  id: PostId;
};

type PostUpdateRequest = {
  author: DirektcenterAuthor;
  body: string;
  attachment: DirektcenterAttachment | null;
  pinned: boolean;
  highlighted: boolean;
  createdAt: string;
  signature?: string;
};

type PostDeleteResponse = {
  id: PostId;
};

export type UserData = {
  id: number;
  email: string;
  displayName: string;
  title: string;
  role: DirektcenterRoleType;
  isAd: 0;
  initiator: string;
  avatar: { path: string };
  iat: number;
  exp: number;
  firebaseCredentials?: FirebaseCredentials;
  isModerationRequired?: boolean;
  adGroupNames: string | null;
  lastRefreshTokenCreatedAt: string;
  latestLoginAt: string;
  loginDurationMs: number;
};

type UpdateAccessTokenParam =
  | SuccessResponse<{
      token: string | null;
      firebaseCredentials?: FirebaseCredentials;
    } | null>
  | ErrorResponse
  | FailResponse;

type UpdateMyUserDetailsRequest = {
  displayName: string;
  title: string;
  avatarPath: string;
};

type UpdateMyUserDetailsResponse = {
  message: string;
  userId: string;
  token: string;
};

type LoginWithTokenRequest = {
  token: string;
};

type LoginWithTokenResponse = {
  token: string;
  firebaseCredentials: FirebaseCredentials;
};

type LoginWithCurrentSessionResponse = {
  token: string;
  firebaseCredentials: FirebaseCredentials;
} | null;

type LoginRequest = {
  email: string;
  password: string;
};

type LoginResponse = {
  token: string;
  firebaseCredentials: FirebaseCredentials;
};

type PendingPost = {
  authorName: string;
  createdAt: string;
  id: string | number;
  isApprovedForGuest: boolean | null;
  streamId?: StreamId;
  status?: string;
  text: string;
  total?: number;
  visitorHash?: string;
};

type PendingPostListResponse = {
  hasGuests: boolean;
  hasMoreItems: boolean;
  pendingPosts: PendingPost[];
};

type PendingPostListType = {
  streamId: StreamId;
  limit: number;
  createdAtOrBefore: string | null;
};

type LoginWithGuestTokenRequest = { token: string };

type LoginWithGuestTokenResponse = { streamId: StreamId; token: string };

type GuestsListResponse = {
  guests: Guest[];
};

type GetUploadUrlResponse = {
  uploadUrl: string;
  filePath: string;
};

type GetAvatarUploadUrlResponse = GetUploadUrlResponse;
type GetFileUploadUrl = GetUploadUrlResponse;

type PendingPostStatisticsResponse = {
  statistics: StatisticsAttribute[];
};

type GuestDetailsRequest = {
  token?: string;
};

export type GuestDetailsResponse = {
  streamId: StreamId;
  displayName: string;
  title: string;
  avatar: {
    path: string;
  };
  isModerationRequired: boolean;
  role: 'guest';
};

const api = new Api();

export default api;

const apiEvents = { ERROR, SUCCESS, USER_DATA };

export { apiEvents };
