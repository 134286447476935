import { useContext, useState, useEffect, ChangeEvent } from 'react';
import { MdAddAPhoto, MdDelete } from 'react-icons/md';
import axios from 'axios';
import cn from 'classnames';

import removeJpegMetadata from 'utils/removeJpegMetadata';
import NotificationContext from 'contexts/NotificationContext';
import LinkButton from 'components/LinkButton/LinkButton';
import Loader from 'components/Loader/Loader';
import { ReactComponent as ProfileSvtIcon } from 'img/direktcenter-svt-icon.svg';
import { ReactComponent as ProfileGuestIcon } from 'img/direktcenter-guest-icon.svg';

import api from 'services/api';
import s from './AvatarUpload.module.scss';

type AvatarUploadProps = {
  onChange: (path: string) => void;
  path: string;
  changeLoadingState: (loading: boolean) => void;
  guest: boolean;
};

const AvatarUpload = ({
  onChange,
  path,
  changeLoadingState,
  guest = false,
}: AvatarUploadProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const { flashNotification } = useContext(NotificationContext);

  useEffect(() => {
    changeLoadingState(isLoading);
  }, [isLoading, changeLoadingState]);

  const handleFileUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    const response = await api.getAvatarUploadUrl();
    if (response.status !== 'success') {
      flashNotification({
        type: 'error',
        message:
          'Tyvärr fungerar inte bilduppladdning just nu. Vänligen försök igen',
      });
      return;
    }
    const strippedFile = await removeJpegMetadata(file);

    try {
      setIsLoading(true);
      await axios.put(response.data.uploadUrl, strippedFile);
      onChange(response.data.filePath);
    } catch (error) {
      flashNotification({
        type: 'error',
        message:
          'Tyvärr fungerar inte bilduppladdning just nu. Vänligen försök senare',
      });
    }
  };
  const avatarUrl = process.env.REACT_APP_AVATAR_BASE_URL + path;

  // Note: each avatarUpload needs a unique id to avoid cross references
  const inputId = guest ? 'avatarFileGuest' : 'avatarFileUser';

  return (
    <div className={s.root}>
      <div className={s.imageWrapper}>
        {isLoading && <Loader padding={false} absoluteCenter />}
        {path && (
          <img
            src={avatarUrl}
            alt="Förhandsvisning av bylinebild"
            className={cn(s.image, { [s.isLoading]: isLoading })}
            onLoad={() => setIsLoading(false)}
          />
        )}
        {!isLoading && !path && !guest && (
          <ProfileSvtIcon className={s.avatarIcon} title="SVT-bylinebild" />
        )}
        {!isLoading && !path && guest && (
          <ProfileGuestIcon className={s.avatarIcon} title="Gäst-bylinebild" />
        )}

        <input
          id={inputId}
          name="avatarFile"
          type="file"
          accept="image/*"
          onChange={handleFileUpload}
          className={s.fileUploader}
          disabled={isLoading}
        />
      </div>
      <div className={s.textWrapper}>
        <label htmlFor={inputId} className={s.linkButton}>
          <MdAddAPhoto className={s.mdAddAPhoto} />
          Ladda upp bild
        </label>
        <LinkButton
          className={s.link}
          onClick={() => {
            onChange('');
          }}
          type="button"
          disabled={isLoading || !path}
        >
          <MdDelete className={s.mdDelete} />
          Ta bort bild
        </LinkButton>
      </div>
    </div>
  );
};

export default AvatarUpload;
