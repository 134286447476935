import React, { useContext, useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate, useParams } from 'react-router-dom';

import api, { GuestDetailsResponse } from '../services/api';
import UserContext from 'contexts/UserContext';
import NotificationContext from 'contexts/NotificationContext';

import AvatarImage from 'components/Avatar/AvatarImage';
import Module from 'components/Module/Module';
import Banner from 'components/Banner/Banner';
import Button from 'components/Button/Button';
import PageTitle from 'components/PageTitle/PageTitle';
import PageContent from 'components/PageContent/PageContent';
import PageMainContainer from 'components/PageMainContainer/PageMainContainer';
import SVTFooter from 'components/SVTFooter/SVTFooter';
import { GUEST } from 'utils/roles';
import s from './LoginGuestPage.module.scss';

const LoginGuestPage = () => {
  const { token } = useParams();
  const { user, loginWithGuestToken, logout } = useContext(UserContext);
  const { flashNotification } = useContext(NotificationContext);
  const navigate = useNavigate();

  const [guestDetails, setGuestDetails] = useState<GuestDetailsResponse | null>(
    null
  );
  const [guestNotFound, setGuestNotFound] = useState(false);

  useEffect(() => {
    const getGuestDetails = async () => {
      const response = await api.guestDetails(token);
      if (response.status !== 'success') {
        setGuestNotFound(true);
      } else {
        // TODO: Handle possible missing guest details
        setGuestDetails(response.data);
      }
    };
    if (user && user.token === token) {
      navigate(`/streams/${user.streamId}`);
    } else {
      getGuestDetails();
    }
  }, [user, setGuestNotFound, token, navigate]);

  const doLogout = async () => {
    await logout();
    navigate('/login');
  };

  const doLogin = async () => {
    // TODO: Handle token not found (error)
    // Show or not show helping error page
    const streamId = await loginWithGuestToken({ token });
    if (streamId) {
      navigate(`/streams/${streamId}`);
    } else {
      flashNotification({
        type: 'error',
        message: 'Inloggningen misslyckades. Försök igen.',
      });
      navigate('/login');
    }
  };

  const doContinue = async () => {
    if (user) {
      await doLogout();
    }

    await doLogin();
  };

  if (guestNotFound) {
    return (
      <PageContent>
        <PageMainContainer>
          <Helmet title="Det gick inte att logga in" />
          <PageTitle>Det gick inte att logga in</PageTitle>

          <Module>
            <p>
              Kontrollera att du har kopierat rätt länk eller kontakta den som
              bjudit in dig till Direktcenter.
            </p>
          </Module>
        </PageMainContainer>
        <SVTFooter />
      </PageContent>
    );
  }

  if (!guestDetails) {
    return null;
  }

  const title = user ? 'Logga in som gäst' : 'Välkommen till Direktcenter';
  return (
    <PageContent>
      <PageMainContainer>
        <Helmet title={title} />

        <Module noPadding className={s.root}>
          <div className={s.wrapper}>
            <div className={s.bylineWrapper}>
              <div className={s.bylineHeader}>Gäst</div>
              <div className={s.byline}>
                {guestDetails && (
                  <React.Fragment>
                    <AvatarImage
                      className={s.image}
                      role={GUEST}
                      displayName={guestDetails.displayName}
                      avatar={guestDetails.avatar}
                      size="large"
                      darkMode
                    />
                    <div className={s.displayName}>
                      {guestDetails.displayName}
                    </div>
                    {guestDetails.title && (
                      <div className={s.title}>{guestDetails.title}</div>
                    )}
                  </React.Fragment>
                )}
              </div>
            </div>
            <div className={s.content}>
              <h1 className={s.title}>{title}</h1>
              <p className={s.intro}>
                Direktcenter är SVTs verktyg för direktrapportering och chatt.
                Om du stöter på problem: kontakta den som bjudit in dig till
                Direktcenter.
              </p>

              <h2 className={s.subTitle}>Detta kan du göra</h2>
              <ul className={s.list}>
                <li>
                  {guestDetails.isModerationRequired
                    ? 'Svara på ett urval av inkommande frågor och åsikter'
                    : 'Svara på alla inkommande frågor och åsikter'}
                </li>
                <li>Skriva egna inlägg</li>
              </ul>

              {user && (
                <Banner
                  className={s.banner}
                  text={`Du är redan inloggad som ${user.displayName}, vill du verkligen fortsätta som gäst?`}
                />
              )}
              <Button
                className={s.continueButton}
                variant="primary"
                onClick={doContinue}
                data-testid="continueGuestLogin"
              >
                Fortsätt
              </Button>
            </div>
          </div>
        </Module>
      </PageMainContainer>
      <SVTFooter />
    </PageContent>
  );
};

export default LoginGuestPage;
