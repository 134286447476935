import { Dialog } from '@mui/material';
import NotificationContext from 'contexts/NotificationContext';
import { useContext } from 'react';
import api from 'services/api';
import styles from './ToggleHighlightsDialog.module.scss';
import { ReactComponent as WarningIcon } from 'img/direktcenter-info-icon.svg';
import Button from 'components/Button/Button';
import { trackEvent } from 'utils/statistics';
import { Stream } from 'Types';

type HighlightsHeaderProps = {
  stream: Stream;
  streamId: string;
  isOpen: boolean;
  close: () => void;
};

export default function ToggleHighlightsDialog({
  stream,
  streamId,
  isOpen,
  close,
}: HighlightsHeaderProps) {
  const { flashError } = useContext(NotificationContext);

  const currentIsHighlightsBoxVisible = stream.isHighlightsBoxVisible;

  const trackUserInteraction = (eventName: string) => {
    trackEvent({
      name: eventName,
    });
  };

  const toggle = async () => {
    const response = await api.streamUpdate(streamId, {
      ...stream,
      isHighlightsBoxVisible: !currentIsHighlightsBoxVisible,
      isVisitorPostingEnabled: stream.isVisitorPostingEnabled ?? false,
    });

    if (response.status !== 'success') {
      flashError(
        `Misslyckades med att ${
          currentIsHighlightsBoxVisible ? 'dölja' : 'visa'
        } överblick. Kontrollera din anslutning och försök igen.`
      );
    }
  };

  return (
    <Dialog open={isOpen} fullWidth={true} maxWidth="xs">
      <div className={styles.root}>
        <WarningIcon />
        {currentIsHighlightsBoxVisible ? (
          <>
            <h3>
              Är du säker på att du vill dölja Överblick för alla besökare?
            </h3>
            <p>
              Alla händelser kommer fortsätta vara markerade och du kan alltid
              välja att visa Överblick igen.
            </p>
          </>
        ) : (
          <h3>Är du säker på att du vill visa Överblick igen?</h3>
        )}
        <div className={styles.buttonWrapper}>
          <Button
            variant={'secondary'}
            big
            onClick={() => {
              close();
              trackUserInteraction(
                currentIsHighlightsBoxVisible
                  ? 'highlights-box-cancel-visibility-off'
                  : 'highlights-box-cancel-visibility-on'
              );
            }}
          >
            Nej
          </Button>
          <Button
            variant={'primary'}
            big
            onClick={async () => {
              close();
              toggle();
              trackUserInteraction(
                currentIsHighlightsBoxVisible
                  ? 'highlights-box-confirm-visibility-off'
                  : 'highlights-box-confirm-visibility-on'
              );
            }}
          >
            Ja
          </Button>
        </div>
      </div>
    </Dialog>
  );
}
