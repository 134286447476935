import { useState } from 'react';
import { DirektcenterPost, Stream } from '../../Types';
import AnimateIn from 'components/AnimateIn/AnimateIn';
import HighlightItem from './HighlightItem';
import styles from './Highlights.module.scss';
import HighlightsHeader from './HighlightsHeader';
import HighlightsBoxVisibleButton from './HighlightsBoxVisibleButton';
import ToggleHighlightsDialog from './ToggleHighlightsDialog';
import { trackEvent } from 'utils/statistics';

type HighlightsProps = {
  posts: DirektcenterPost[];
  stream: Stream;
  streamId: string;
};

export default function Highlights({
  posts,
  stream,
  streamId,
}: HighlightsProps) {
  const [isExpanded, setIsExpanded] = useState<boolean>(true);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const isHighlightsBoxVisible = stream.isHighlightsBoxVisible;

  const trackUserInteraction = (eventName: string) => {
    trackEvent({
      name: eventName,
    });
  };

  if (!posts.length) {
    return null;
  }

  return (
    <div className={styles.root}>
      <HighlightsHeader
        isExpanded={isExpanded}
        setIsExpanded={setIsExpanded}
        isHighlightsBoxVisible={isHighlightsBoxVisible}
      />
      <AnimateIn isVisible={isExpanded} animateInOnMount={false}>
        <ol className={styles.list}>
          {posts.map((post) => (
            <HighlightItem key={post.id} post={post} />
          ))}
        </ol>
        <HighlightsBoxVisibleButton
          onClick={() => {
            setIsDialogOpen(true);
            trackUserInteraction(
              isHighlightsBoxVisible
                ? 'highlights-box-open-dialog-visibility-off'
                : 'highlights-box-open-dialog-visibility-on'
            );
          }}
          isHighlightsBoxVisible={isHighlightsBoxVisible}
        />
      </AnimateIn>
      <ToggleHighlightsDialog
        stream={stream}
        streamId={streamId}
        isOpen={isDialogOpen}
        close={() => {
          setIsDialogOpen(false);
        }}
      />
    </div>
  );
}
